
import React from 'react';
import Footer from "./Footer";
import {Rules, aTeamRule} from "./Rules"
import GameArea from "./GameArea";
import {getTeamMembers} from "../../common/api"
import {updateHighScore, updateNumGames } from "./Score";
import "./fearnot.css"


class FearNotGameContainer extends React.Component {
    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.state  = {
            selectedSource:'fearless',
            currentRule:Rules[0],
            isNot:false,
            score:0,
            isDisplayStats:false,
        }
    }

    async setupGame() {
        const apiCards = getTeamMembers();

        apiCards.then((resp) =>{
            console.log(resp)
            let possibleCards = resp.cards
            shuffle(possibleCards);
            console.log(possibleCards.length);
            this.setState({
                cards: possibleCards,
                activeCard:possibleCards[0],
                activeCards:possibleCards.slice(0, 4)
            });
        });

    }

    startNewGame() {
        let state = {
            endGame: false,
            currentRule:Rules[0],
            isNot:false,
            score:0,
            isDisplayStats:false
        };

        let possibleCards = this.state.cards;
        shuffle(possibleCards);
        state.card = possibleCards;
        state.activeCard = possibleCards[0];
        state.activeCards = possibleCards.slice(0, 4);
        state.activeCards.forEach((card) =>{card.isCorrect = false});
        this.setState(state)

    }

    componentDidMount() {
        this.setupGame();
    }

    clickHandler = (obj) =>{
        if(!this.state.isDisplayStats && this.state.score <=10 ){
            this.setState({isDisplayStats: true, selectedCard:obj});
            return
        } else{
            this.setState({isDisplayStats: false, selectedCard:null});
        }


        let isCorrect = this.state.currentRule.rule(
            obj,
            this.state.activeCard,
            this.state.activeCards
        );

        if (this.state.isNot){
            isCorrect = !isCorrect
        }
        let score = 0;
        if (isCorrect){
            score = this.state.score + 1;
            this.changeRule(score)

        } else {
            const gradedCards = this.state.activeCards.slice().map((card) => {
                card.isCorrect = this.state.currentRule.rule(
                    card,
                    this.state.activeCard,
                    this.state.activeCards
                );
                if (this.state.isNot){
                    card.isCorrect = !card.isCorrect
                }
                return card
            });
            this.endGame();
            this.setState({activeCards: gradedCards, isDisplayStats:true, selectedCard:null});
        }


    };

    changeRule = (score) =>{
        let isNot = (Math.floor(Math.random() * 10) % 3 === 0);
        const notNot = score > 20 && !isNot && Math.random() * 10 >=7;
        const numRules = Math.min(Rules.length, Math.floor(score / 5));

        let currentRule = Rules[Math.floor(Math.random() * numRules)];
        if ((Math.random() * 50) >= 49){
            currentRule = aTeamRule;
        }
        //let currentRule = Rules[4];
        let possibleCards = this.state.cards;
        shuffle(possibleCards);
        let numCards = isNot ? Math.floor(score / 10) + 4 : Math.floor(score / 4) + 4;
        let activeCards = possibleCards.slice(0, numCards);
        activeCards = currentRule.activeCards ? currentRule.activeCards(activeCards, possibleCards) : activeCards;
        let activeCard = activeCards[0];
        shuffle(activeCards);
        console.log(activeCards);
        activeCards.forEach((card) =>{
            card.isCorrect = false;
            card.key = generateKey(10)
        });
        this.setState({ score, currentRule, possibleCards, activeCard, activeCards, isNot, notNot})
    };



    endGame = () =>{
        const prevScore = this.state.score;
        updateHighScore(prevScore);
        updateNumGames();
        this.setState({prevScore, endGame:true});
    };

    render() {

        return (
            this.state.cards ?
                <div className={'fearnotGameContainer'}>
                    <div className={'header'}>Fear Not!</div>
                    {this.state.endGame &&

                    <div >
                        <div>Great Job.  You scored: {this.state.prevScore}</div>
                        <button onClick={()=>{this.startNewGame()}}>New Game?</button>
                    </div>

                    }
                    <div>
                        <div className={`rule ${this.state.isNot && 'not'}`}>
                            {this.state.currentRule.text(this.state.activeCard, this.state.isNot, this.state.notNot)}
                        </div>
                        < GameArea
                            cards={this.state.activeCards}
                            clickHandler={this.clickHandler}
                            rule={this.state.currentRule}
                            isDisplayStats={this.state.isDisplayStats}
                            selectedCard={this.state.selectedCard}
                        />
                    </div>
                    <Footer
                        score={this.state.score}
                        prevScore={this.state.prevScore}
                    />

                </div>
                :
                <div>Loading</div>

        )
    }
}

function shuffle(array) {
    let m = array.length,
        t,
        i;
    while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }
}

function dec2hex (dec) {
    return ('0' + dec.toString(16)).substr(-2)
}

function generateKey (len){
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('')
}

export default FearNotGameContainer
