import logo from './logo.svg';
import {Link} from "react-router-dom";
import {CardDeck, Card, ButtonGroup, Button, CardGroup, Navbar, NavbarBrand, Container } from "react-bootstrap"
import faker from "faker";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import {buildDate, buildId} from "./common/settings";
import person_1 from "./img/person_1.jpg"
import person_2 from "./img/person_2.jpg"
import person_3 from "./img/person_3.jpg"
import person_4 from "./img/person_4.jpg"
import person_5 from "./img/person_5.jpg"
import person_6 from "./img/person_6.jpg"
import person_7 from "./img/person_7.jpg"
import person_8 from "./img/person_8.jpg"
import person_9 from "./img/person_9.jpg"

function App() {
    console.log(buildDate)
    console.log('Build Id: ', buildId)
    const getRandomSkinTone = () =>{
        const skinTones = [
            person_1,
            person_2,
            person_3,
            person_4,
            person_5,
            person_6,
            person_7,
            person_8,
            person_9,
        ]

        return skinTones[Math.floor(Math.random() * skinTones.length)]
    }

    const NameCard = () =>{
        return (
            <Card>
                <Card.Body style={{backgroundColor:'#ccc4d5', color:'#494f56'}}>

                    <Card.Text>
                        {faker.name.firstName()} {faker.name.lastName()}
                    </Card.Text>
                </Card.Body>

            </Card>
        )
    }

    const ImageCard = () =>{
        return (
            <Card>
                <Card.Body style={{ padding:0, border:'10px solid #ccc4d5'}}>
                    <img style={{ padding:0 }} src={getRandomSkinTone()}/>
                </Card.Body>

            </Card>
        )
    }

    const RandomRow = () =>{
        return (
            Math.floor(Math.random()*2) % 2 ?

            <CardGroup>
                <NameCard/>
                <ImageCard/>
                <NameCard/>
                <ImageCard/>
            </CardGroup>
            :
            <CardGroup>
                <ImageCard/>
                <NameCard/>
                <ImageCard/>
                <NameCard/>
            </CardGroup>
        )
    }

  return (
    <div className="App">
      <CardDeck>
        <Card>
          <Card.Body>
            <Card.Title>Matching Game</Card.Title>
            <Card.Text>
              Match the name to the face in this twist on memory
            </Card.Text>
              <RandomRow/>
              <RandomRow/>
              <RandomRow/>
              <RandomRow/>
          </Card.Body>
          <Card.Footer>
              <Link to="/matching?rows=4&columns=4"><Button variant="success">Easy</Button></Link>{' '}
              <Link to="/matching?rows=6&columns=6"><Button variant="warning">Medium</Button></Link>{' '}
              <Link to="/matching?rows=8&columns=8"><Button variant="danger">Hard</Button></Link>{' '}
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
              <Card.Title>Fear Not</Card.Title>
              <Card.Text>
                  Test your knowledge of the herd, but pay attention to those NOT's.
              </Card.Text>
              <CardGroup style={{maxWidth:'200px', margin:'auto'}}>
                  <Card>
                      <Card.Body>

                          <Card.Text>
                              NOT
                          </Card.Text>
                      </Card.Body>

                  </Card>
                   <ImageCard />
              </CardGroup>
              <CardGroup style={{padding:0, margin:'auto'}}>
                  <ImageCard/>
                  <ImageCard/>
                  <ImageCard/>
                  <ImageCard/>
              </CardGroup>
          </Card.Body>
          <Card.Footer>
              <Link to="/fearnot"><Button variant="success">Play</Button></Link>
          </Card.Footer>
        </Card>
      </CardDeck>
    </div>
  );
}
export default App;
