import React, { useState } from 'react';

const GameArea = ({cards, clickHandler, rule, isDisplayStats, selectedCard}) =>{
    const displayStats = {
        Name: 'txt',
        Herd: 'division',
        Title: 'jobTitle'

    };
    return (
        <div>
            {cards.map((card)=>{
            return (
                <div className={ `fearNotCard ${card.isCorrect ? ' correct' : ''} ${selectedCard && selectedCard.txt === card.txt ? 'active': ''}` }
                     onClick={() =>{clickHandler(card)}}
                     key={card.key}>

                    {rule.cardRender(card)}

                    {isDisplayStats &&
                        <ul>
                            {Object.entries(displayStats).map( ([key, value]) => <li>{card[value]}</li>)}
                        </ul>
                    }

                </div>
        )
        })}
            {isDisplayStats && selectedCard &&
                <div>
                    <button
                        onClick={() =>{ clickHandler(selectedCard) }}>
                        Next
                    </button>
                </div>
            }
        </div>
    )
};

export default GameArea
