import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function GameSelector({sendResults}) {
    const [selectedSource, setSelectedSource] = useState('fearless');
    const [numColumns, setNumColumns] = useState(4);
    const [numRows, setNumRows] = useState(4);
    return(
        <Modal.Dialog>
            <Modal.Header closeButton>
                <Modal.Title>Game Setup</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{Color:"black"}}>
                    Please select a source
                    <select value={selectedSource} onChange={(e) =>{
                        console.log(e.target.value);
                        setSelectedSource(e.target.value)
                    }}>
                        <option value={"fearless"}>Fearless</option>
                        <option value={"presidents"}>Presidents</option>
                    </select>
                </div>
                <div style={{Color:"black"}}>
                    How many columns?
                    <select value={numColumns} onChange={(e) =>{
                        console.log(e.target.value);
                        setNumColumns(e.target.value)
                    }}>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                    </select>
                </div>
                <div style={{Color:"black"}}>
                    How many Rows?
                    <select value={numRows} onChange={(e) =>{
                        console.log(e.target.value);
                        setNumRows(e.target.value)
                    }}>
                        <option value={4}>4</option>
                        <option value={6}>6</option>
                        <option value={8}>8</option>
                    </select>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => sendResults({selectedSource, columns:numColumns, rows:numRows})}>Start</Button>
            </Modal.Footer>
        </Modal.Dialog>
    )
}
